import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {useSpring, animated } from 'react-spring';
import { navigate } from "gatsby";

function NotFoundPage() {

  const animation = useSpring({opacity: 1, from: {opacity: 0}, duration: 250, delay: 250, onRest: () => navigate("gastinfo")})
  return (
    <Layout>
      <SEO title="404: Not found" />
       <animated.div style={animation}>      
       <h1 className="flex justify-center">404: Not found </h1>
      </animated.div>
    </Layout>
  );
}

export default NotFoundPage;
